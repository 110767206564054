<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table"
           style="width: 70%; margin-top: 10px">
        <el-form :model="addGoodsForm"
                 :rules="addGoodsFormRules"
                 ref="addGoodsFormRef"
                 label-width="100px">
          <el-form-item v-if="isEdit" label="商品ID" prop="title">
            <el-input disabled v-model="goodInfo.goodsId"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" >
            <el-input v-model="addGoodsForm.title"></el-input>
          </el-form-item>
          <el-form-item label="供应商商品编码" >
            <el-input v-model="addGoodsForm.goodsCode"></el-input>
          </el-form-item>
          <el-form-item v-if="hasChannelUI" label="商品渠道" prop="channel">
            <el-select v-model="addGoodsForm.channel" placeholder="请选择商品渠道">
              <el-option v-for="(item,index) in channelList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="hasExpressTemplateUI" label="快递模板" prop="templateId">
            <el-select v-model="addGoodsForm.templateId" placeholder="请选择快递模板">
              <el-option v-for="(item,index) in templateList" :key="index" :label="item.templateName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="私域组件" >
            <div style="display: flex;align-items: center;height: 40px">
              <el-switch v-model="addGoodsForm.ifPrivate" active-value="1"
                inactive-value="0" active-color="#13ce66" inactive-color="#ddd">
              </el-switch>
              <el-input style="margin-left: 10px;" v-if="addGoodsForm.ifPrivate==1" v-model="addGoodsForm.privateTemplateCode" placeholder="请输入券模板ID"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="交易组件" >
            <div style="display: flex;align-items: center;height: 40px">
              <el-switch v-model="addGoodsForm.mini" active-value="1"
                inactive-value="0" active-color="#13ce66" inactive-color="#ddd">
              </el-switch>
              <el-input style="margin-left: 10px;" v-if="addGoodsForm.mini==1" v-model="addGoodsForm.alipayGoodsId" placeholder="请输入支付宝商品ID"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="签收不退" >
            <div style="display: flex;align-items: center;height: 40px">
              <el-switch v-model="addGoodsForm.canReturn" active-value="1"
                inactive-value="0" active-color="#13ce66" inactive-color="#ddd">
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item label="原价" prop="costPrice">
            <el-input v-model="addGoodsForm.costPrice"></el-input>
          </el-form-item>
          <el-form-item label="基础邮费" prop="postFee">
            <el-input v-model="addGoodsForm.postFee"></el-input>
          </el-form-item>
          <el-form-item label="会员价">
            <el-input v-model="addGoodsForm.specialRate"></el-input>
          </el-form-item>
          <el-form-item label="采购价">
            <el-row v-for="(item,index) in addGoodsForm.purchaseMoneyList" :key="index" style="margin-bottom: 10px;">
              <el-col :span="4" style="margin-right: 25px;">
                <el-input v-model="item.money" placeholder="采购价"></el-input>
              </el-col>
              <el-col :span="9">
                <el-date-picker
                  v-model="item.beginDate"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="生效日期">
                  </el-date-picker>
              </el-col>
              <el-col :span="9">
                <el-date-picker
                  v-model="item.endDate"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="失效日期">
                  </el-date-picker>
              </el-col>
              <el-col :span="1" style="cursor: pointer;" >
                <div @click="removePurchase(index)">X</div>
              </el-col>
            </el-row>
            <el-row>
              <div style="text-align: center;border: 1px dashed #ccc; line-height: 40px;border-radius: 5px;cursor: pointer;" @click="addPurchase">+ 添加采购价</div>
            </el-row>
          </el-form-item>
          <el-form-item v-if="hasGoodExpireUI" label="优惠时效">
             <el-date-picker
              v-model="addGoodsForm.expireDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="商品库存" prop="stock">
            <el-input v-model="addGoodsForm.stock" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="排序"
                        prop="sort">
            <el-input v-model="addGoodsForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="是否上架"
                        prop="status">
            <el-select v-model="addGoodsForm.status"
                       placeholder="请选择是否上架">
              <el-option label="上架"
                         :value="1"></el-option>
              <el-option label="不上架"
                         :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="主图" prop="mainPic">
            <ImgUpload v-model="addGoodsForm.mainPic" :limit="limit" />
          </el-form-item>
          <el-form-item v-if="hasWufuUI" label="支付宝图片">
            <!-- 图片集 -->
            <el-upload class="upload-demo"
                       :action="uploadURL"
                       :on-remove="handleRemove"
                       :on-success="handleSuccess"
                       :headers="headerObj">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                与第一张主图保持一致
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="图片集" prop="pics">
            <ImgUpload v-model="addGoodsForm.pics"/>
          </el-form-item>
          <el-form-item label="商品描述">
            <el-input type="textarea" v-model="addGoodsForm.remark"></el-input>
          </el-form-item>
          <el-form-item  label="账单分期" >
              <span>花呗 </span>
              <el-switch v-model="addGoodsForm.isFq" active-value="1"
              inactive-value="0" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
              &nbsp; &nbsp; &nbsp; &nbsp; 
              <span>信用卡 </span>
              <el-switch v-model="addGoodsForm.isCreditCard" active-value="1"
              inactive-value="0" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
          </el-form-item>

          <el-form-item label="分期期数" v-if="addGoodsForm.isFq == 1 || addGoodsForm.isCreditCard == 1" >
              <el-checkbox-group v-model="addGoodsForm.fqNum">
                <el-checkbox :label="item" v-for="(item, index) in ['3','6','12']" :key="index">{{item}}期</el-checkbox>
              </el-checkbox-group>
          </el-form-item>
          <el-form-item label="手续费承担方" v-if="addGoodsForm.isFq == 1 || addGoodsForm.isCreditCard == 1" >
               <el-radio checked>卖家承担</el-radio>
          </el-form-item>
           <!-- <el-form-item label="分期收费比例" v-if="addGoodsForm.isFq == 1 || addGoodsForm.isCreditCard == 1" >
                <el-input-number v-model="addGoodsForm.fqSellerPercent" controls-position="right"  :min="1" :max="100"></el-input-number>
          </el-form-item> -->
          <el-form-item label="先用后付" v-if="addGoodsForm.mini == 1" >
            <div style="display: flex;align-items: center;height: 40px">
              <el-switch v-model="addGoodsForm.zmFirstEnjoy" active-value="1"
                inactive-value="0" active-color="#13ce66" inactive-color="#ddd">
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item v-if="hasAntUI" label="参与森林能量" >
              <el-switch v-model="addGoodsForm.ant" active-value="1"
    inactive-value="0" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
          </el-form-item>
          <el-form-item label="69码" v-if="addGoodsForm.ant == 1" >
            <el-input v-model="addGoodsForm.sixNine"></el-input>
          </el-form-item>
          <el-form-item v-if="addGoodsForm.ant == 1" label="商品生产时间">
             <el-date-picker
              v-model="addGoodsForm.goodsCreateTime"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="商品有效时长" v-if="addGoodsForm.ant == 1" >
            <el-input v-model="addGoodsForm.goodsValidDays"></el-input>
          </el-form-item>
          <el-form-item label="假券优惠时效" v-if="hasFakeCouponUI" >
            <el-switch v-model="addGoodsForm.ifFake" active-value="1"
    inactive-value="0" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
    &nbsp; &nbsp; &nbsp;
            <el-date-picker
              v-model="addGoodsForm.fakeEndTime"
              value-format="yyyy-MM-dd"
              v-if="addGoodsForm.ifFake==1"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <!-- 操作 -->
          <el-form-item v-if="!isEdit">
            <el-button type="primary" class="btnAdd" @click="addGoods()">添加商品</el-button>
            <el-button @click="resetForm('addGoodsFormRef')">重置商品</el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button type="primary" class="btnAdd" @click="addGoods()">保存</el-button>
          </el-form-item>
        </el-form>

      </div>
    </el-card>

  </div>
</template>

<script>
import ImgUpload from '../common/ImgUpload/index.vue'
export default {
  components: { ImgUpload },
  data () {
    return {
      // 分页
      pages: {
        query: '',
        currentPage: 1,
        pageSize: 3,
      },
      limit: 5,
      isEdit: false,
      goodInfo: {},
      uploadURL: '/api/GoodsAdminModule/adolf-goods/goodsImgUploadAlipay',
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },
      // 添加商品表单
      addGoodsForm: {
        title: '',
        goodsCode: '',
        costPrice: '',
        specialRate: '',
        mainPic: [],
        purchaseMoneyList: [],
        postFee: '',
        // stock: '',
        // category: '',
        pics: [],
        sort: '',
        status: 1,
        remark: '',
        channel: '',
        templateId: '',
        expireDate: '',
        goodsCreateTime: '',
        goodsValidDays: '',
        ant: '0',
        sixNine: 0,
        ifFake: 0,
        fakeEndTime: 0,
        mini: 0,
        alipayGoodsId: '',
        ifPrivate: 0,
        canReturn: 0,
        isFq: 0,
        isCreditCard: 0,
        zmFirstEnjoy: 0,
        fqNum: ['3'],
        fqSellerPercent: 100,
        privateTemplateCode: '',
      },

      // 商品验证规则
      addGoodsFormRules: {
        title: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        costPrice: [
          { required: true, message: '请输入商品原价', trigger: 'blur' },
        ],
        specialRate: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ],
        templateId: [
          { required: true, message: '请选择快递模板', trigger: 'blur' },
        ],
        postFee: [
          { required: true, message: '请输入邮费', trigger: 'blur' },
        ],
        mainPic: [
          { required: true, message: '主图不能为空', trigger: 'change' },
        ],
        // stock: [{ required: true, message: '请输入商品库存', trigger: 'blur' }],
        sort: [
          { required: true, message: '请输入商品排序', trigger: 'blur' },
          // { validator: isNum, trigger: 'blur' },
        ],
        pics: [
          { required: true, message: '图片集不能为空', trigger: 'change' },
        ],
      },
      channelList: [],
      templateList: [],
      hasChannelUI: false,
      hasAntUI: false,
      hasGoodExpireUI: false,
      hasExpressTemplateUI: false,
      hasWufuUI: false,
      hasFakeCouponUI: false,
      alipayUrl: '',
    }
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.goodInfo = localStorage.getItem('goods-' + query.id);
      if(this.goodInfo){
        this.goodInfo = JSON.parse(this.goodInfo);
        console.log(this.goodInfo)
        this.isEdit = true;
        this.addGoodsForm.title = this.goodInfo.title;
        this.addGoodsForm.goodsCode = this.goodInfo.goodsCode;
        this.addGoodsForm.purchaseMoneyList = this.goodInfo.purchaseMoneyList || [];
        this.addGoodsForm.costPrice = this.goodInfo.costPrice || 0
        this.addGoodsForm.specialRate = this.goodInfo.specialRate || 0
        this.addGoodsForm.mainPic = this.transferMainPic(this.goodInfo.mainPic)
        if(this.goodInfo.stock){
          this.addGoodsForm.stock = this.goodInfo.stock;
        }
        this.addGoodsForm.pics = this.transferMainPic(this.goodInfo.pics)
        this.addGoodsForm.sort = this.goodInfo.sort || 1
        this.addGoodsForm.remark = this.goodInfo.remark
        this.addGoodsForm.status = this.goodInfo.status
        this.addGoodsForm.goodsCreateTime = this.goodInfo.goodsCreateTime
        this.addGoodsForm.goodsValidDays = this.goodInfo.goodsValidDays
        this.addGoodsForm.postFee = this.goodInfo.postFee
        // this.addGoodsForm.purchaseMoney = this.goodInfo.purchaseMoney
        this.addGoodsForm.expireDate = this.goodInfo.expireDate
        this.addGoodsForm.channel = this.goodInfo.channel
        this.addGoodsForm.templateId = this.goodInfo.templateId
        if(this.goodInfo.ant){
          this.addGoodsForm.ant = this.goodInfo.ant.toString()
        }
        this.addGoodsForm.sixNine = this.goodInfo.sixNine
        if(this.goodInfo.ifFake){
          this.addGoodsForm.ifFake = this.goodInfo.ifFake.toString()
        }
        this.addGoodsForm.fakeEndTime = this.goodInfo.fakeEndTime
        this.addGoodsForm.alipayGoodsId = this.goodInfo.alipayGoodsId
        this.addGoodsForm.mini = this.goodInfo.mini? this.goodInfo.mini.toString() : 0
        this.addGoodsForm.ifPrivate = this.goodInfo.ifPrivate? this.goodInfo.ifPrivate.toString() : 0
        this.addGoodsForm.privateTemplateCode = this.goodInfo.privateTemplateCode
        this.addGoodsForm.canReturn = this.goodInfo.canReturn? this.goodInfo.canReturn.toString() : 0
        this.addGoodsForm.isFq = this.goodInfo.isFq? this.goodInfo.isFq.toString() : 0
        this.addGoodsForm.zmFirstEnjoy = this.goodInfo.zmFirstEnjoy? this.goodInfo.zmFirstEnjoy.toString() : 0
        this.addGoodsForm.isCreditCard = this.goodInfo.isCreditCard? this.goodInfo.isCreditCard.toString() : 0
        this.addGoodsForm.fqNum = this.goodInfo.fqNum? this.goodInfo.fqNum.split(',') : ['3']
        this.addGoodsForm.fqSellerPercent = this.goodInfo.fqSellerPercent? this.goodInfo.fqSellerPercent.toString() : 100
      }

    }
    this.hasAntUI = window.hasAntUI();
    this.hasWufuUI = window.hasWufuUI();
    this.hasFakeCouponUI = window.hasFakeCouponUI();
    this.hasChannelUI = window.hasChannelUI();
    this.hasGoodExpireUI = window.hasGoodExpireUI();
    this.hasExpressTemplateUI = window.hasExpressTemplateUI();
    this.$api.GetGoodsChannelList({currentPage: 1, pageSize: 50}).then((res) => {
      if (res.code !== 200) return;
      this.channelList = res.data.records
    })
    this.$api.GetTemplateList({currentPage: 1, pageSize: 300}).then((res) => {
      if (res.code !== 200) return;
      this.templateList = res.data.records
    })
  },
  methods: {

    addPurchase(){
      this.addGoodsForm.purchaseMoneyList.push({
        status: 1,
        money: 1,
        beginDate: '',
        endDate: '',
        goodsId: this.goodInfo ? this.goodInfo.id : null,
      })
    },
    removePurchase(index){
      console.log(index)
      this.addGoodsForm.purchaseMoneyList = this.addGoodsForm.purchaseMoneyList.filter((o,i) => i != index)
    },
    // 添加商品
    addGoods () {
      //  预校验

      this.$refs.addGoodsFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项')
        }
        let timeCheck = true;
        this.addGoodsForm.purchaseMoneyList.map(o => {
          if(!o.beginDate || !o.endDate) timeCheck = false;
        })
        if(!timeCheck) return this.$message.error('采购价请选择生效日期和失效日期')
        let params = {...this.addGoodsForm};
        params.pics = params.pics.join(',')
        params.mainPic = params.mainPic.join(',')

        if(this.hasWufuUI){
          params.alipayGoodsImgCode = this.alipayUrl;
        }

        // 字符串转number
        params.costPrice = parseFloat(params.costPrice)
        params.specialRate = parseFloat(params.specialRate)
        params.stock = params.stock == '--' ? null : parseFloat(params.stock)
        params.postFee = parseFloat(params.postFee)
        params.goodsCreateTime = params.goodsCreateTime ? new Date(params.goodsCreateTime) : null
        params.fqNum = params.fqNum.join(',') 

        if(params.mini == 1 && !params.alipayGoodsId.length) return this.$message.error('请输入支付宝商品ID')
        if(params.ifPrivate == 1 && !params.privateTemplateCode.length) return this.$message.error('请输入私域插件ID')
        if(params.mini == 0){
          params.zmFirstEnjoy = 0;
        }
        if(params.costPrice - params.specialRate > 0 && !params.expireDate && this.hasGoodExpireUI) return this.$message.error('请选择优惠失效时间')
        if(this.isEdit){
          params = {...this.goodInfo, ...params};
          delete params.stock;
          this.$api.UpdateGoods(params).then((res) => {
            if (res.code !== 200) {
              return this.$message.error('修改商品失败：' +res.message)
            } else {
              this.$message.success('修改商品成功')
            }
            localStorage.removeItem('goods-' + this.goodInfo.id)
            // 刷新列表
            this.$router.push('/goods')
          })
        }else{
          this.$api.AddGoods(params).then((res) => {
            if (res.code !== 200) {
              return this.$message.error('添加商品失败：' +res.message)
            } else {
              this.$message.success('添加商品成功')
            }
            this.$router.push('/goods')
          })
        }
      })
    },

    // 重置表单
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    // 图片集移除
    handleRemove (file, fileList) {
      this.alipayUrl = '';
    },
    // 图片集预览
    handlePreview (file) {
      // console.log(file)
      this.picsdialogImageUrl = file.response.data
      this.previewVisible = true
    },
    // 图片上传完成之后
    handleSuccess (res) {
      
      this.alipayUrl = res.data
    },
  },
}
</script>

<style lang="scss" scoped></style>
